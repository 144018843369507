import { Field, change, reduxForm, getFormValues, hasSubmitFailed, isInvalid } from 'redux-form';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import { MessageSection, ActionButton, TextStyle, InlineLink } from '@able/react';
import { Error, CancelActivation } from '../../components';
import { nextPage, getCurrentRouteName, focusOnHeading, getdayMonthYearFromDate, submitFailure, scrollToBody, getAbleSpriteSheet, focusOnElement } from '../../adapters/utils';
import { AbleTextField } from '../../components/form/AbleTextField';
import { AbleCheckBox } from '../../components/form/AbleCheckBox';
import AbleSelect from '../../components/form/AbleSelect';
import ReadOnlyField from '../your-contact-details/components/readonlyField/readOnlyField';
import {
    isEmpty,
    validateLicense,
    getDlCardNumberLength,
    validateLicenseCardNumber,
    validateMedicare,
    validatePassport,
    validateExpireDate,
    validateInternationalPassport,
    validateImmiCard
} from '../../adapters/validations';
import { addDataLayerUser, addDataLayerEventInfo } from '../../adapters/analytics-utils';
import * as errorCodeMeta from '../../adapters/errorCode';
import { countries as countryList, userProfileInfoMeta, IdTypeDataLayerMap } from '../../adapters/constants';

import { mfaLogin, flushMfaFromEditFlag } from '../../adapters/authentication-utils';
import FieldText from '../../common/components/fieldText/fieldText';

import ID_PAGE_CONSTANTS from './constants';
import BirthDate from '../../components/BirthDate/BirthDate';
import MobileImageModal from '../../components/MobileImageModal';
import FullPageError from '../../components/FullPageError/FullPageError';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import EditFieldModal from './components/EditFieldModal/index';
import RemoveMiddleNameModal from './components/RemoveMiddleNameModal/index';
import { validateBirthDate, validateSingleName, validateFirstName, validateLastName, validateMiddleName, splitDOB, IDENTITY_TYPE_IMAGES } from './helper';
import { redirectToMyBoost } from '../../adapters/esim-utils';
const ableSpriteSheet = getAbleSpriteSheet();

const compareDlOptions = (a, b) => {
    if (a.label > b.label) {
        return 1;
    } else if (b.label > a.label) {
        return -1;
    }
    return 0;
};

const sortedDlElementsState = ID_PAGE_CONSTANTS.idDetails.drivingLicense.dlStateElement.options.sort(compareDlOptions);

class YourIdDetails extends Component {
    constructor(props) {
        super(props);
        const { appData: { uiState: { isMfafromEdit, editingField } = {} } = {} } = this.props;
        this.state = {
            type: 'personal',
            hideMiddleName: false,
            showSingleName: this.props.mononymousUser || false,
            back: false,
            editingField: isMfafromEdit ? editingField : undefined,
            editBasicDetailsData: {
                ...this.getInitialBasicDetailsData()
            },
            showMessage: false
        };
        this.allowNext = true;
        this.myRef = React.createRef();
        this.clearFormFields = this.clearFormFields.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validationErrorFlag = false;
        this.setHideMiddleName = this.setHideMiddleName.bind(this);
        this.submitIdentityForm = this.submitIdentityForm.bind(this);
        this.scrollToMyRef = this.scrollToMyRef.bind(this);
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.onCloseEditFieldModal = this.onCloseEditFieldModal.bind(this);
        this.onContinueEditFieldModal = this.onContinueEditFieldModal.bind(this);
        this.onContinueRemoveMiddleName = this.onContinueRemoveMiddleName.bind(this);

        this.dlImageRef = React.createRef();
        this.d2ImageRef = React.createRef();
    }

    getInitialBasicDetailsData = () => {
        const { initialValues = {} } = this.props;
        return {
            firstName: initialValues.firstName,
            middleName: initialValues.middleName,
            noMiddleName: initialValues.noMiddleName,
            familyName: initialValues.familyName,
            birthDate: initialValues.birthDate
        };
    }

    componentWillMount() {
        const that = this;
        const {
            actions: { moduleInit },
            loading: { isModuleLoaded }
        } = this.props;

        if (!isModuleLoaded) {
            moduleInit();
        }
        window.addEventListener('popstate', that.clearFormFields, false);
    }

    componentDidMount() {
        const { appData: { uiState: { isMfafromEdit } = {} } = {} } = this.props;
        isMfafromEdit && flushMfaFromEditFlag();
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        scrollToBody('body');
        focusOnHeading();
        const stepperData = {
            totalSteps: this.props.appData.uiState.isEsimActivation ? 7 : 6,
            currentStep: 3,
            isLastStepCompleted: false
        };
        this.props.actions.updateStepperCustomData(stepperData);
        this.allowNext = false;
        if (document.getElementById('edit-message-section')) {
            focusOnElement('#edit-message-section');
        }

        if (document.getElementById('info-txt')) {
            focusOnElement('#info-txt');
        }
    }

    componentDidUpdate(prevProps) {
        const {
            errors: prevErrors = {}
        } = prevProps;

        const {
            errors = {}
        } = this.props;

        if (prevErrors.hasError !== errors.hasError && errors.hasError) {
            this.scrollToMyRef();
            this.resetUpdatedPersonalDetails();
        }
    }

    /**
     * PAGE RENDER LOGIC.
     * handles page navigation once the service call for validate customer is done.
     *
     * @param {any} props
     * @memberof CustomerDetails
     */
    componentWillReceiveProps(props) {
        const {
            loading: { formProcessed, isDocumentValidated },
            actions: { updateStepperCustomData }
        } = props;

        if (props.identityType !== this.props.identityType) {
            addDataLayerUser({ type: userProfileInfoMeta.IDENTITY_TYPE, value: IdTypeDataLayerMap[props.identityType] });
        }
        // nextPage(updateStepperCustomData);

        if (formProcessed && isDocumentValidated && this.allowNext) {
            nextPage(updateStepperCustomData);
        }

        if (props.cardColour !== this.props.cardColour) {
            this.props.dispatch(change('identityDetailsForm', 'expiry', null));
        }
    }

    onHoverDImage = (fldName) => {
        this[fldName].current.focus();
    }

    resetUpdatedPersonalDetails = () => {
        this.setState({
            editBasicDetailsData: {
                ...this.getInitialBasicDetailsData()
            }
        });
    }

    /**
     * Lock persistant form state once processed
     *
     * @param {any} props
     * @memberof CustomerDetails
     */
    /*  componentWillUnmount() {
        const { loading: { formProcessed }, actions: { lockState } } = this.props;
        if (formProcessed) {
            lockState();
        }
    } */

    onContinueRemoveMiddleName() {
        this.setState((state) => ({ ...state, showMessage: true, editingField: undefined, editBasicDetailsData: { ...state.editBasicDetailsData, middleName: null, noMiddleName: true } }));
        const { actions: { resetErrors } = {} } = this.props;
        resetErrors();
    }

    onCloseEditFieldModal() {
        this.setState({ editingField: undefined });
    }

    onContinueEditFieldModal(data) {
        this.setState((state) => {
            const _state = {
                ...state,
                editingField: undefined,
                editBasicDetailsData: {
                    ...state.editBasicDetailsData,
                    [data.key]: data.value
                },
                showMessage: true
            };
            focusOnElement('#edit-message-section');
            focusOnElement('#info-message-section');

            if (data.key === 'middleName') {
                _state.editBasicDetailsData.noMiddleName = false;
            }
            return _state;
        });
        const { actions: { resetErrors } = {} } = this.props;
        resetErrors();
    }

    onCloseUpdatedFieldMessage = () => {
        this.setState(state => ({
            editBasicDetailsData: {
                ...state.editBasicDetailsData
            },
            showMessage: false
        }));
    }

    getUpdateFieldStatus = () => {
        const { initialValues = {} } = this.props;
        const { editBasicDetailsData: updatedData } = this.state;
        return {
            isBirthDateUpdated: initialValues.birthDate !== updatedData.birthDate,
            isFirstNameUpdated: initialValues.firstName !== updatedData.firstName,
            isFamilyNameUpdated: initialValues.familyName !== updatedData.familyName,
            isMiddleNameUpdated: initialValues.middleName !== updatedData.middleName
        }
    }

    shouldShowUpdateButton = fieldName => {
        const { isBirthDateUpdated, isFamilyNameUpdated, isFirstNameUpdated } = this.getUpdateFieldStatus();
        if (!(isBirthDateUpdated || isFamilyNameUpdated || isFirstNameUpdated)) return true;
        if (fieldName === 'firstName') return isFirstNameUpdated;
        else if (fieldName === 'familyName') return isFamilyNameUpdated;
        else if (fieldName === 'birthDate') return isBirthDateUpdated;
    }

    getFieldUpdatedMessageInfo = () => {

        const {
            uiState: { loggedIn },
            customer: { dvsCheckRequired } = {},
            loggedInCustomerData: { anonymous } = {}
        } = this.props.appData;
        const {
            loggedInCustomerData: { mononymousUser } = {}
        } = this.props;
        const editBasicDetails = loggedIn && dvsCheckRequired && !anonymous;
        if (!editBasicDetails) return { show: false };

        const { isBirthDateUpdated, isFamilyNameUpdated, isFirstNameUpdated, isMiddleNameUpdated } = this.getUpdateFieldStatus();
        let fields = [], restrictedFields = [], message, showStoreMessage = false;
        const firstName = 'given name';
        const familyName = 'family name';
        const birthDate = 'date of birth';
        const middleName = 'middle name';
        const singleName = 'single name';
        if (isBirthDateUpdated) {
            fields.push(birthDate);
            restrictedFields = [firstName, familyName];
        }
        else if (isFirstNameUpdated) {
            fields.push(mononymousUser ? singleName : firstName);
            restrictedFields = [familyName, birthDate];
        }
        else if (isFamilyNameUpdated) {
            fields.push(familyName);
            restrictedFields = [firstName, birthDate];
        }
        if (isMiddleNameUpdated) fields.push(middleName);

        if (isFirstNameUpdated || isFamilyNameUpdated || isBirthDateUpdated) {
            message = `Your ${fields.join(' and ')} will be updated once your ID is verified. 
            For security reasons, you can't edit any other details.`;
            showStoreMessage = true;
        } else if (isMiddleNameUpdated) {
            message = `Your ${middleName} will be updated once your ID is verified.`;
            showStoreMessage = false;
        }
        return {
            show: !!fields.length,
            message,
            showStoreMessage
        }
    }

    checkLevelOfAuthentication(loa, editingField) {
        if (loa === 'LOA2') {
            this.setState({ editingField });
        } else {
            mfaLogin(loa, editingField);
        }
    }

    render() {
        const {
            loading: { lockFields, isDocumentValidated },
            actions: { resetErrors },
            identityType,
            cardColour,
            handleSubmit,
            hasErrorOccurred,
            errors: { hasServerError, hasError, errorCode },
            customerDataFetchSuccess,
            stateOfIssue,
            formFields,
            initialValues = {},
            loggedInCustomerData: { anonymous, mononymousUser = false } = {}
        } = this.props;
        const {
            uiState: { appLock, isEsimActivation, loggedIn },
            customer: { dvsCheckRequired, data: { dobEditable, personalDetailsEditable } = {} } = {},
            levelOfAuthentication
        } = this.props.appData;
        const { editingField, editBasicDetailsData = {} } = this.state;
        const { PPA_IDV_TITLE_DISABLED = 'false' } = window.appConfig || {};
        // LOCAL VALIDATION
        const showDrivingLicenseForm = identityType === 'AUS_DRIVERS_LICENSE';
        const showMedicareForm = identityType === 'MEDICARE_CARD';
        const showAustralianPassportForm = identityType === 'AUS_PASSPORT';
        const showAustralianVisaForm = identityType === 'AUS_VISA';
        const showImmiCardForm = identityType === 'IMMI_CARD';
        const disableFields = appLock || lockFields || isDocumentValidated;
        const disableBasicDetails = loggedIn && dvsCheckRequired && customerDataFetchSuccess && !anonymous;
        const editBasicDetails = loggedIn && dvsCheckRequired && customerDataFetchSuccess && !anonymous;

        const skipDay = cardColour === 'G';
        const { pageTitle: title } = ID_PAGE_CONSTANTS;
        const {
            idTypeElement,
            idDetails: { drivingLicense, medicareCard, australianPassport, australianVisa, immiCard }
        } = ID_PAGE_CONSTANTS;
        const { appNumberElement: passportNumber } = australianPassport;
        const { imCardNumberElement: immiCardNumber } = immiCard;
        const bannerImageProps = this.findBannerImage();
        const bannerImage = bannerImageProps.image;
        const bannerImage1 = bannerImageProps.image1;
        const altText = bannerImageProps.altText;
        const altText1 = bannerImageProps.altText1;

        const validateIdType = (value) => (value ? undefined : idTypeElement.emptyError);

        const {
            dlStateElement,
            dlNumberElement: licenseNumber,
            dlCardNumberElement: dlCardNumber,
            dlSingleNameElement: singleName,
            dlFirstNameElement: firstName,
            dlMiddleNameElement: middleName,
            dlLastNameElement: familyName,
            dobElement: dob,
            salutation
        } = drivingLicense;

        const { intlppNumberElement, intlppCountryElement: countryOfIssue } = australianVisa;
        const countryNamesList = countryOfIssue.options.concat(countryList);
        const { mcCardNumberElement: cardNumber, mccardColorElement, mcCardExpiryElement: expiry, mcRefNumberElement: individualReferenceNumber } = medicareCard;
        const errorProps = {
            maximumIdText: {
                errorText: 'Your ID check failed',
                errorMessage:
                    '<p>You’ve reached the maximum number of attempts.</p><p>To complete your ID check and activation,' +
                    " we’ll need to assist you in store.</p><p>To secure a specific time with one of our team members, you can book an appointment at your nearest store at <a href='https://www.telstra.com.au/telstra-store/appointments' style='text-decoration:none' target='_blank'>Telstra.com.</a></p>"+
                    '<p>Please remember to bring your ID.</p>'
            },
            serverErrorText: {
                errorText: 'Something went wrong',
                errorMessage: 'We’re unable to process the request at this time. Please try again later.'
            }
        };
        const errorData = hasServerError || (hasError && errorCode === errorCodeMeta.NETWORK_FAILURE) ? errorProps.serverErrorText : errorProps.maximumIdText;
        if (hasErrorOccurred) {
            addDataLayerEventInfo('error', 'Hardstop', 'unavailable', errorData.errorMessage);
        }
        const { message: fieldUpdatedMessage, show: showFieldUpdatedMessage, showStoreMessage: showFieldUpdatedStoreMessage } = this.getFieldUpdatedMessageInfo();
        const containerClass = isDocumentValidated ? 'isValidated' : '';
        const commonClass = this.props.noMiddleName ? 'onlyLabel' : '';
        const isPersonalDetailsEditable = personalDetailsEditable && !disableFields && levelOfAuthentication === 'LOA2';
        return (
            <div className={`container ppa-block id-page ${containerClass}`}>
                <GlobalBanner onPage="idv" className="infobanner-idv" />
                {!hasErrorOccurred && (
                    <Fragment>
                        <div className="row mb-0 pb-0">
                            <TextStyle tabIndex="-1" element="h1" alias="HeadingA" className="col-lg-12 id-page-heading">
                                {title}
                            </TextStyle>
                        </div>
                        <div className="row">
                            <div className="col-lg-5">
                                {/* {showFieldUpdatedMessage && this.renderFieldUpdatedMessage(fieldUpdatedMessage, showFieldUpdatedStoreMessage)} */}
                                {isDocumentValidated && (
                                    <div id="info-txt" className="mt-3" aria-live="polite" tabIndex="-1" aria-atomic="true">
                                        <MessageSection variant="Information" developmentUrl={ableSpriteSheet} description="Please note you can't change ID details." />
                                    </div>
                                )}
                                <form onSubmit={handleSubmit(this.submitIdentityForm)} className={`id-page-main-form  ${isDocumentValidated ? 'isvalidated' : ''}`}>
                                    <fieldset disabled={disableFields}>
                                        <legend>
                                           <TextStyle element="h2" alias="HeadingB">Your ID details</TextStyle>
                                        </legend>
                                        {!isDocumentValidated && (
                                            <TextStyle alias="TextBigBodyShort" className="mtsm">Please enter your personal details exactly as they appear on your ID.</TextStyle>)
                                        }
                                        {!appLock && this.renderErrors()}
                                        <div className="select-identity mt-4xl">
                                                {isDocumentValidated ? (
                                                    <ReadOnlyField label={idTypeElement.label} value={this.getLabelForValue(formFields.idType, idTypeElement.options)} valueClassName="mt2l" />
                                                ) : (
                                                    <Field
                                                        name="identityType"
                                                        id="identityType"
                                                        component={AbleSelect}
                                                        label={idTypeElement.label}
                                                        options={idTypeElement.options}
                                                        validate={validateIdType}
                                                        onChange={resetErrors}
                                                    />)}
                                        </div>
                                        {showDrivingLicenseForm && (
                                            <div name="dlFields">
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={dlStateElement.label} value={formFields.stateOfIssue} valueClassName="mt2l" />
                                                    ) : (
                                                        <Field
                                                            name="stateOfIssue"
                                                            component={AbleSelect}
                                                            className="mt-4xl"
                                                            label={dlStateElement.label}
                                                            options={sortedDlElementsState}
                                                            aria-required="true"
                                                            tabIndex="0"
                                                            onChange={resetErrors}
                                                            id="stateOfIssue"
                                                        />)}
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={licenseNumber.label} value={formFields.licenseNumber} valueClassName="mtsm" />
                                                    ) : (
                                                        <Field
                                                            name="licenseNumber"
                                                            component={AbleTextField}
                                                            className="mt-4xl"
                                                            type="text"
                                                            label={licenseNumber.label}
                                                            placeholder={licenseNumber.placeholder}
                                                            aria-required="true"
                                                            onChange={resetErrors}
                                                            maxLength={10}
                                                            id="licenseNumber"
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                    {doesStateLicenseHaveCardNumber(stateOfIssue) && (isDocumentValidated ? (
                                                        <ReadOnlyField label={dlCardNumber.label} value={formFields.dlCardNumber} valueClassName="mtsm" />
                                                    ) : (
                                                        <Field
                                                            name="dlCardNumber"
                                                            component={AbleTextField}
                                                            className="mt-4xl"
                                                            type="text"
                                                            label={dlCardNumber.label}
                                                            placeholder={dlCardNumber.placeholder}
                                                            aria-required="true"
                                                            onChange={resetErrors}
                                                            maxLength={getDlCardNumberLength(stateOfIssue)}
                                                            id="dlCardNumber"
                                                            normalizeValueOnBlur={true}
                                                        />)
                                                    )}
                                                    {!isDocumentValidated && (
                                                        <MobileImageModal disabled={!stateOfIssue} altText={altText} bannerImage={bannerImage} onKeyDown={this.handleEnterClick} buttonText="Can't find your licence number?" />)}
                                            </div>
                                        )}
                                        {showMedicareForm && (
                                            <div name="medicareFields">
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={cardNumber.label} value={formFields.cardNumber} valueClassName="mt2l" />
                                                    ) : (
                                                        <Field
                                                            name="cardNumber"
                                                            id="cardNumber"
                                                            component={AbleTextField}
                                                            className="mt-4xl"
                                                            type="text"
                                                            label={cardNumber.label}
                                                            placeholder={cardNumber.placeholder}
                                                            onChange={resetErrors}
                                                            maxLength={10}
                                                            aria-required="true"
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                    {!isDocumentValidated && (
                                                        <MobileImageModal altText={altText} bannerImage={bannerImage} onKeyDown={this.handleEnterClick} buttonText="Can't find your card number?" />)}
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={mccardColorElement.label} value={this.getLabelForValue(formFields.cardColour, mccardColorElement.options)} valueClassName="mtsm" />
                                                    ) : (
                                                        <Field
                                                            name="cardColour"
                                                            id="cardColour"
                                                            component={AbleSelect}
                                                            className="mt-4xl"
                                                            label={mccardColorElement.label}
                                                            options={mccardColorElement.options}
                                                            tabIndex="0"
                                                            onChange={resetErrors}
                                                            aria-required="true"
                                                            allowEmpty={false}
                                                        />)}
                                                    <Field name="expiry" component={BirthDate} wrapperClassName="mt-4xl" label={expiry.label} onChange={resetErrors} aria-required="true" skipDay={skipDay} />
                                                    {isDocumentValidated ? <ReadOnlyField label={individualReferenceNumber.label} value={formFields.individualReferenceNumber} valueClassName="mtsm" /> : (
                                                        <Field
                                                            name="individualReferenceNumber"
                                                            id="individualReferenceNumber"
                                                            component={AbleSelect}
                                                            className="mt-4xl"
                                                            label={individualReferenceNumber.label}
                                                            options={individualReferenceNumber.options}
                                                            tabIndex="0"
                                                            onChange={resetErrors}
                                                            aria-required="true"
                                                            allowEmpty={false}
                                                        />)}
                                            </div>
                                        )}
                                        {showAustralianVisaForm && (
                                            <div name="australianVisaFields">
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={intlppNumberElement.label} value={formFields.passportNumber} valueClassName="mt2l" />
                                                    ) : (
                                                        <Field
                                                            name="visaNumber"
                                                            component={AbleTextField}
                                                            className="mt-4xl"
                                                            type="text"
                                                            label={intlppNumberElement.label}
                                                            placeholder={intlppNumberElement.placeholder}
                                                            onChange={resetErrors}
                                                            aria-required="true"
                                                            maxLength={14}
                                                            id="visaNumber"
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                    {!isDocumentValidated && (
                                                        <MobileImageModal altText={altText} bannerImage={bannerImage} onKeyDown={this.handleEnterClick} buttonText="Can't find your passport number?" />)}
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={countryOfIssue.label} value={this.getLabelForValue(formFields.countryOfIssue, countryNamesList)} valueClassName="mtsm" />
                                                    ) : (
                                                        <Field
                                                            name="countryOfIssue"
                                                            id="countryOfIssue"
                                                            component={AbleSelect}
                                                            label={countryOfIssue.label}
                                                            options={countryNamesList}
                                                            tabIndex="0"
                                                            onChange={resetErrors}
                                                            aria-required="true"
                                                            allowEmpty={false}
                                                            className="mt-4xl"
                                                        />)}
                                            </div>
                                        )}

                                        {showAustralianPassportForm && (
                                            <div name="austrlianPassportFields">
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={passportNumber.label} value={formFields.passportNumber} valueClassName="mt2l" />
                                                    ) : (
                                                        <Field
                                                            name="passportNumber"
                                                            id="passportNumber"
                                                            component={AbleTextField}
                                                            className="mt-4xl"
                                                            type="text"
                                                            label={passportNumber.label}
                                                            placeholder={passportNumber.placeholder}
                                                            aria-required="true"
                                                            onChange={resetErrors}
                                                            maxLength={9}
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                    {!isDocumentValidated && (
                                                        <MobileImageModal altText={altText} bannerImage={bannerImage} onKeyDown={this.handleEnterClick} buttonText="Can't find your passport number?" />)}
                                            </div>
                                        )}
                                        {showImmiCardForm && (
                                            <div name="immiCardFields">
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={immiCardNumber.label} value={formFields.immiCardNumber} valueClassName="mt2l" />
                                                    ) : (
                                                        <Field
                                                            name="immiCardNumber"
                                                            component={AbleTextField}
                                                            type="text"
                                                            label={immiCardNumber.label}
                                                            placeholder={immiCardNumber.placeholder}
                                                            aria-required="true"
                                                            onChange={resetErrors}
                                                            maxLength={9}
                                                            className="mt-4xl"
                                                            id="immiCardNumber"
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                    {!isDocumentValidated && (
                                                        <MobileImageModal altText={altText} altText1={altText1} bannerImage={bannerImage} bannerImage1={bannerImage1} onKeyDown={this.handleEnterClick} buttonText="Can't find your immicard number?" />)}
                                            </div>
                                        )}
                                        </fieldset>
                                        <fieldset disabled={!editBasicDetails && (disableFields || disableBasicDetails)}>
                                            <legend>
                                                <TextStyle element="h2" alias="HeadingB" className="id-page-form-subheader">Your details</TextStyle>
                                            </legend>
                                        {!editBasicDetails && (<div>
                                            {PPA_IDV_TITLE_DISABLED === 'false' && (isDocumentValidated ?
                                                <ReadOnlyField label={salutation.label} value={this.getLabelForValue(formFields.title, salutation.options)} valueClassName="mtsm" />
                                                :
                                                <Field
                                                    name="title"
                                                    component={AbleSelect}
                                                    className="mbsm"
                                                    label={salutation.label}
                                                    options={salutation.options}
                                                    aria-required="true"
                                                    tabIndex="0"
                                                    onChange={resetErrors}
                                                    disabled={disableFields || disableBasicDetails}
                                                    id="title"
                                                />
                                            )}
                                            {!isDocumentValidated &&
                                                    <Field
                                                        name="mononymousUser"
                                                        component={AbleCheckBox}
                                                        checked={this.state.showSingleName}
                                                        label={singleName.singleNameLabel}
                                                        onChange={this.setShowSingleName}
                                                    />
                                                }
                                            {this.state.showSingleName ? (
                                                isDocumentValidated ? (
                                                    <ReadOnlyField label={singleName.label} value={formFields.singleName} valueClassName="mtsm" />
                                                ) : (
                                                    <Field
                                                        name="singleName"
                                                        component={AbleTextField}
                                                        type="text"
                                                        helpText={singleName.helpText}
                                                        className="mtsm"
                                                        label={singleName.label}
                                                        placeholder={singleName.placeholder}
                                                        aria-required="true"
                                                        onChange={resetErrors}
                                                        maxLength={40}
                                                        disabled={disableFields || disableBasicDetails}
                                                        id="singleName"
                                                        normalizeValueOnBlur={true}
                                                        autoComplete={singleName.autocomplete}
                                                    />)
                                            ) : (
                                                <div>
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={firstName.label} value={formFields.firstName} valueClassName="mtsm" />
                                                    ) : (
                                                        <Field
                                                            name="firstName"
                                                            component={AbleTextField}
                                                            type="text"
                                                            label={firstName.label}
                                                            autoComplete={firstName.autocomplete}
                                                            placeholder={firstName.placeholder}
                                                            aria-required="true"
                                                            onChange={resetErrors}
                                                            maxLength={40}
                                                            disabled={disableFields || disableBasicDetails}
                                                            className="mtsm"
                                                            id="firstName"
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                        {isDocumentValidated ? (
                                                            <ReadOnlyField label={middleName.label} value={formFields.middleName} valueClassName="mtsm" />
                                                        ) : (
                                                            <Field
                                                                name="middleName"
                                                                component={AbleTextField}
                                                                className={`mt-4xl ${commonClass}`}
                                                                type="text"
                                                                autoComplete={middleName.autocomplete}
                                                                label={middleName.label}
                                                                placeholder={middleName.placeholder}
                                                                onChange={resetErrors}
                                                                disabled={false}
                                                                maxLength={40}
                                                                id="middleName"
                                                                normalizeValueOnBlur={true}
                                                            />)}
                                                        <Field name="noMiddleName" component={AbleCheckBox} checked={commonClass} className={`no-middle-name ${commonClass}`} label={middleName.noMiddleNameLabel} onChange={this.setHideMiddleName} />
                                                    {isDocumentValidated ? (
                                                        <ReadOnlyField label={familyName.label} value={formFields.familyName} valueClassName="mtsm" />
                                                    ) : (
                                                        <Field
                                                            name="familyName"
                                                            component={AbleTextField}
                                                            className="mtxxl"
                                                            type="text"
                                                            autoComplete={familyName.autocomplete}
                                                            label={familyName.label}
                                                            placeholder={familyName.placeholder}
                                                            aria-required="true"
                                                            onChange={resetErrors}
                                                            maxLength={50}
                                                            disabled={disableFields || disableBasicDetails}
                                                            id="familyName"
                                                            normalizeValueOnBlur={true}
                                                        />)}
                                                </div>
                                            )}
                                                <div className="mt-4xl dateOfBirth"> <Field name="birthDate" autocomplete={dob.autocomplete} component={BirthDate} onChange={resetErrors} type="date" label={dob.label} aria-required="true" /></div>
                                        </div>)}
                                        {editBasicDetails && (<div className="edit-id-details">
                                            {!disableFields && isPersonalDetailsEditable &&
                                                <div id="edit-message-section" aria-live="polite" aria-atomic="true" tabIndex="-1">
                                                    <MessageSection
                                                        className="edit-message-section mtsm"
                                                        developmentUrl={ableSpriteSheet}
                                                        variant="Information"
                                                        description={
                                                            mononymousUser ? 'You may update either your single name or date of birth.' : 'You may update either your given name/s, family name or date of birth.'
                                                        } />
                                                </div>
                                            }
                                            {showFieldUpdatedMessage && this.renderFieldUpdatedMessage(fieldUpdatedMessage, showFieldUpdatedStoreMessage)}
                                            {mononymousUser ? (
                                                <div className="edit-id-details-item edit-id-details-data mtsm">
                                                    <TextStyle alias="Label">
                                                        Single name
                                                    </TextStyle>
                                                    <FieldText className="edit-id-details-data-value">{editBasicDetailsData.firstName}</FieldText>
                                                    {isPersonalDetailsEditable && this.shouldShowUpdateButton('firstName') &&
                                                        <ActionButton
                                                            element="button"
                                                            variant="LowEmphasis"
                                                            className="boost-able-button"
                                                            label="Update single name"
                                                            onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'singleName')}
                                                        />}
                                                </div>
                                            ) : (
                                                <Fragment>
                                                    <div className="edit-id-details-item edit-id-details-data mtsm">
                                                        <TextStyle alias="Label">
                                                            Given name/s
                                                        </TextStyle>
                                                        <FieldText className="edit-id-details-data-value">{editBasicDetailsData.firstName}</FieldText>
                                                        {isPersonalDetailsEditable && this.shouldShowUpdateButton('firstName') &&
                                                            <ActionButton
                                                                element="button"
                                                                variant="LowEmphasis"
                                                                className="boost-able-button"
                                                                label="Update given name"
                                                                onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'firstName')}
                                                            />}
                                                    </div>
                                                    <div className="edit-id-details-item edit-id-details-data mt-4xl">
                                                        <TextStyle alias="Label">
                                                            Middle name/s
                                                        </TextStyle>
                                                        {editBasicDetailsData.middleName && <FieldText className="edit-id-details-data-value">{editBasicDetailsData.middleName}</FieldText>}
                                                        {isPersonalDetailsEditable && editBasicDetailsData.middleName && <div>
                                                            <ActionButton
                                                                element="button"
                                                                variant="LowEmphasis"
                                                                className="boost-able-button"
                                                                label="Update middle name"
                                                                onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'middleName')}
                                                            />
                                                            <ActionButton
                                                                element="button"
                                                                variant="Destructive"
                                                                className="boost-able-button"
                                                                label="Remove"
                                                                onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'removeMiddleName')}
                                                            />
                                                        </div>}
                                                        {isPersonalDetailsEditable && !editBasicDetailsData.middleName && (
                                                            <ActionButton
                                                                element="button"
                                                                variant="LowEmphasis"
                                                                className="boost-able-button"
                                                                label="Add middle name"
                                                                onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'middleName')}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="edit-id-details-item edit-id-details-data mt-4xl">
                                                        <TextStyle alias="Label">
                                                            Family name
                                                        </TextStyle>
                                                        <FieldText className="edit-id-details-data-value">{editBasicDetailsData.familyName}</FieldText>
                                                        {isPersonalDetailsEditable && this.shouldShowUpdateButton('familyName') && (
                                                            <ActionButton
                                                                element="button"
                                                                variant="LowEmphasis"
                                                                className="boost-able-button"
                                                                label="Update family name"
                                                                onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'familyName')}
                                                            />
                                                        )}
                                                    </div>
                                                </Fragment>
                                            )}
                                            <div className="edit-id-details-item edit-id-details-data mt-4xl">
                                                <TextStyle alias="Label">
                                                    <strong>Date of birth</strong>
                                                </TextStyle>
                                                <FieldText className="edit-id-details-data-value">{editBasicDetailsData.birthDate}</FieldText>
                                                {isPersonalDetailsEditable && this.shouldShowUpdateButton('birthDate') && dobEditable && (
                                                    <ActionButton
                                                        element="button"
                                                        variant="LowEmphasis"
                                                        className="boost-able-button updateDateBtn"
                                                        label="Update date of birth"
                                                        onClick={() => this.checkLevelOfAuthentication(levelOfAuthentication, 'birthDate')}
                                                    />
                                                )}
                                            </div>
                                        </div>)}
                                    </fieldset>
                                        <div className={'id-check-consent'} tabIndex="-1">
                                            <TextStyle alias="HeadingC" element="h2" >
                                                Permission to verify your ID
                                            </TextStyle>
                                            <TextStyle alias="TextBodyShort" className="mtsm">
                                                By clicking on the 'Continue' button, you agree to have your ID checked with the issuer of that ID.
                                            </TextStyle>
                                        </div>
                                    {this.renderVerifyButton()}
                                    <InlineLink>
                                    <TextStyle alias="TextBodyShort" className='privacy-statement' tabIndex="-1">
                                        Information about how we collect, store, use and share your personal information can be found in our {' '}
                                        <a href="https://boost.com.au/pages/privacy-policy" target={'_blank'}>Privacy Statement</a>.
                                    </TextStyle>
                                    </InlineLink>
                                </form>

                                {isEsimActivation && !appLock && <CancelActivation />}
                            </div>
                            <div className="col-lg-6 t-right">
                                {bannerImage && (
                                    <div>
                                        <img className="idv-image img-fluid hide-in-mobile" alt={altText} src={bannerImage} tabIndex="-1" onMouseEnter={() => this.onHoverDImage("dlImageRef")} ref={this.dlImageRef} />
                                        <TextStyle className="hide-in-mobile card-info" alias="FinePrint" colour="Subtle">Required information is highlighted</TextStyle>
                                    </div>
                                )}
                                {bannerImage1 && (
                                    <div>
                                        <img className="idv-image img-fluid hide-in-mobile mtxl" alt={altText1} src={bannerImage1} tabIndex="-1" onMouseEnter={() => this.onHoverDImage("d2ImageRef")} ref={this.d2ImageRef} />
                                        <TextStyle className="hide-in-mobile card-info" alias="FinePrint" colour="Subtle">Required information is highlighted</TextStyle>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
                {hasErrorOccurred && (isEsimActivation ? (redirectToMyBoost()) : (<FullPageError {...errorData} />))}
                {(editingField && isPersonalDetailsEditable) &&
                    <div>
                        <EditFieldModal editingField={editingField} closeModal={this.onCloseEditFieldModal} onContinue={this.onContinueEditFieldModal} />
                        <RemoveMiddleNameModal isOpen={editingField === 'removeMiddleName' ? true : false} onCancel={this.onCloseEditFieldModal} onContinue={this.onContinueRemoveMiddleName} />
                    </div>
                }
            </div>
        );
    }

    // Function to get the label for a given value from the options array
    getLabelForValue = (value, options) => {
        const selectedOption = options.find(option => option.value === value);
        return selectedOption ? selectedOption.label : value;
    };

    handleEnterClick(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            document.querySelector('#idv-link-text') && document.getElementById('idv-link-text').click();
        }
    }

    setHideMiddleName() {
        const { hideMiddleName } = this.state;
        const { errors, actions } = this.props;
        if (errors.hasError) {
            actions.resetErrors();
        }
        this.setState({
            hideMiddleName: !hideMiddleName
        });
    }

    setShowSingleName = () => {
        const { showSingleName } = this.state;
        const { errors, actions } = this.props;
        if (errors.hasError) {
            actions.resetErrors();
        }
        this.setState({
            showSingleName: !showSingleName
        });
    }

    handleChange(event) {
        this.setState({
            type: event.target.value
        });
    }

    submitIdentityForm() {
        const {
            formFields,
            actions: { updateStepperCustomData, validateDocumentDetails },
            loading: { formProcessed, isDocumentValidated },
            appData: { uiState: { loggedIn } = {}, customer: { dvsCheckRequired } = {} } = {},
            customerDataFetchSuccess,
            loggedInCustomerData: { anonymous } = {}
        } = this.props;
        const editBasicDetails = loggedIn && dvsCheckRequired && customerDataFetchSuccess && !anonymous;
        this.allowNext = true;
        if (formProcessed && isDocumentValidated) {
            nextPage(updateStepperCustomData);
        } else if (editBasicDetails) {
            const birthDate = splitDOB(this.state.editBasicDetailsData.birthDate);
            const fields = { ...formFields, ...birthDate, ...this.state.editBasicDetailsData };
            delete fields.birthDate;
            fields.givenName = this.state.editBasicDetailsData.firstName;

            validateDocumentDetails({ data: fields });
        } else {
            const modifiedFormFields = { ...formFields };
            // Check if the customer has a single name
            if (modifiedFormFields.singleName) {
                // Set the value of singleName in firstName
                modifiedFormFields.firstName = modifiedFormFields.singleName;
                delete modifiedFormFields.singleName;
            }
            validateDocumentDetails({ data: modifiedFormFields });
        }
    }

    renderFieldUpdatedMessage = (message = '', showStoreMessage) => {
        if (!this.state.showMessage) return null;
        return (
            <div id="info-message-section" className="mb-3xl" aria-atomic="true" tabIndex="-1" aria-live="polite">
                <MessageSection variant="Success" developmentUrl={ableSpriteSheet} description={message + `${showStoreMessage ? ' To make more changes, you can bring your ID to a Telstra store.' : ''}`} />
            </div>
        );
    }

    /**
     * Renders the form submittion errors
     *
     * @returns
     * @memberof IdentityDetails
     */
    renderErrors() {
        let errorText = '';
        let errorTitle = 'Your Id could not be verified';
        let analyticsErrorMessage = '';
        const { errors, identityType } = this.props;
        const {
            appData: {
                documentValidation: { failedAttempts = 1 }
            }
        } = this.props;
        const { pageError: { terminalFailure = '', intermittentFailure = {}, maxAttemptFailure = '' } = {} } = ID_PAGE_CONSTANTS;
        const { drivingLicense = {}, australianPassport = {}, medicareCard = {}, australianVisa = {}, immiCard = {} } = intermittentFailure;
        const allowedAttempts = 3;
        if (errors.hasError) {
            this.validationErrorFlag = true;
            if (errors.errorCode === errorCodeMeta.ID_UNAVAILABLE) {
                errorTitle = 'Please try another ID';
                errorText = "We're having trouble processing the type of ID you just used. Please try using one of your other IDs.";
                analyticsErrorMessage = `Id verification match failed ${identityType}`;
            } else if (errors.errorCode === errorCodeMeta.SERVER_ERROR) {
                errorText = terminalFailure;
                this.validationErrorFlag = false;
                analyticsErrorMessage = `Government Database unavailable ${identityType}`;
            } else if (failedAttempts === allowedAttempts) {
                errorText = maxAttemptFailure;
                this.validationErrorFlag = false;
                analyticsErrorMessage = `Customer has 3 failed attempts to match their id ${identityType}`;
            } else if (identityType === 'AUS_DRIVERS_LICENSE') {
                errorText = `${drivingLicense.preCountText}${allowedAttempts - failedAttempts}${drivingLicense.postCountText}`;
                analyticsErrorMessage = `Id verification match failed ${identityType}`;
            } else if (identityType === 'AUS_PASSPORT') {
                errorText = `${australianPassport.preCountText}${allowedAttempts - failedAttempts}${australianPassport.postCountText}`;
                analyticsErrorMessage = `Id verification match failed ${identityType}`;
            } else if (identityType === 'MEDICARE_CARD') {
                errorText = `${medicareCard.preCountText}${allowedAttempts - failedAttempts}${medicareCard.postCountText}`;
                analyticsErrorMessage = `Id verification match failed ${identityType}`;
            } else if (identityType === 'AUS_VISA') {
                errorText = `${australianVisa.preCountText}${allowedAttempts - failedAttempts}${australianVisa.postCountText}`;
                analyticsErrorMessage = `Id verification match failed ${identityType}`;
            } else if (identityType === 'IMMI_CARD') {
                errorText = `${immiCard.preCountText}${allowedAttempts - failedAttempts}${immiCard.postCountText}`;
                analyticsErrorMessage = `Id verification match failed ${identityType}`;
            }
            addDataLayerEventInfo('error', 'Validation', 'unavailable', analyticsErrorMessage);
            return (
                <div ref={this.myRef} id="errorText" aria-live="polite" aria-atomic="true" tabIndex="-1">
                    <MessageSection variant="Warning" developmentUrl={ableSpriteSheet} titleText={errorTitle} titleElement="h2" className="mt-5xl mb4xl" description={errorText} />
                </div>
            );
        } else if (isEmpty(errorText)) {
            this.validationErrorFlag = false;
        }
        return false;
    }

    /**
     * Given the page need the banner to be changed
     * on user flow this function determines the image
     * to be displayed on parent banner
     *
     * @returns
     * @memberof IdentityDetails
     */
    findBannerImage() {
        // TODO optimise this to use variables from render
        const viewPortType = window.innerWidth > 767 ? 'desktop' : 'mobile';
        const { drivingLicense, australianPassport, medicareCard, australianVisa, immiCard } = viewPortType === 'desktop' ? ID_PAGE_CONSTANTS.pageBlockImage.desktop : ID_PAGE_CONSTANTS.pageBlockImage.mobile;
        const { stateOfIssue, identityType } = this.props;
        const imageDetails = {};

        if (identityType === 'AUS_DRIVERS_LICENSE') {
            if (stateOfIssue === 'NSW') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_nsw;
                    imageDetails.altText = drivingLicense.nswImage.cardNoAltText;
            } else if (stateOfIssue === 'QLD') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_qld;
                    imageDetails.altText = drivingLicense.qldImage.cardNoAltText;
            } else if (stateOfIssue === 'NT') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_nt;
                    imageDetails.altText = drivingLicense.ntImage.cardNoAltText;
            } else if (stateOfIssue === 'SA') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_sa;
                    imageDetails.altText = drivingLicense.saImage.cardNoAltText;
            } else if (stateOfIssue === 'ACT') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_act;
                    imageDetails.altText = drivingLicense.actImage.cardNoAltText;
            } else if (stateOfIssue === 'WA') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_wa;
                    imageDetails.altText = drivingLicense.waImage.cardNoAltText;
            } else if (stateOfIssue === 'TAS') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_tas;
                    imageDetails.altText = drivingLicense.tasImage.cardNoAltText;
            } else if (stateOfIssue === 'VIC') {
                    imageDetails.image = IDENTITY_TYPE_IMAGES.drivers_licence_vic;
                    imageDetails.altText = drivingLicense.vicImage.cardNoAltText;
            }
            imageDetails.desc = drivingLicense.desc;
            return imageDetails;
        } else if (identityType === 'AUS_PASSPORT') {
            imageDetails.altText = australianPassport.altText;
            imageDetails.image = IDENTITY_TYPE_IMAGES.auPassport;
            return imageDetails;
        } else if (identityType === 'MEDICARE_CARD') {
            imageDetails.altText = medicareCard.altText;
            imageDetails.image = IDENTITY_TYPE_IMAGES.medicare;
            return imageDetails;
        } else if (identityType === 'AUS_VISA') {
            imageDetails.altText = australianVisa.altText;
            imageDetails.image = IDENTITY_TYPE_IMAGES.intlPassport;
            return imageDetails;
        } else if (identityType === 'IMMI_CARD') {
            imageDetails.altText = immiCard.altTextEvImmiCard;
            imageDetails.altText1 = immiCard.altTextAusImmiCard;
            imageDetails.image = IDENTITY_TYPE_IMAGES.evImmiCard;
            imageDetails.image1 = IDENTITY_TYPE_IMAGES.ausImmiCard;
            return imageDetails;
        }
        return imageDetails;
    }

    scrollToMyRef() {
        if (this.myRef.current !== null) {
            focusOnElement('#errorText');
        }
    }

    /**
     * Renders the verify button and also calls the
     * render error function
     *
     * @returns
     * @memberof IdentityDetails
     */
    renderVerifyButton() {
        const {
            verifyButtonElement: { label }
        } = ID_PAGE_CONSTANTS;
        const {
            uiState: { appLock }
        } = this.props.appData;
        const {
            loading: { isDocumentValidating },
            errors: { hasError }
        } = this.props;
        const { isSelectedFormSubmitted: submitFailed, isSelectedFormInvalid: invalid } = this.props;
        const { validationError = '' } = ID_PAGE_CONSTANTS;
        return (
            <div className="next">
                {appLock && this.renderErrors()}
                {this.validationErrorFlag}
                <SpinningButton
                    type="submit"
                    stylingClass="verify-btn"
                    isDisabled={hasError || isDocumentValidating}
                    isLoading={isDocumentValidating}
                    aria-describedby="server-error-idv-description"
                    buttonText={label}
                />
                {/* // TODO Need to enable it once we implement back */}
                {/* <GoBackButton hide={showLiveChat} goPrevious /> */}
                {submitFailed && invalid && <Error errorText={validationError} isValidationError />}
            </div>
        );
    }

    renderInfoMessage() {
        const { validationSuccessMsg } = ID_PAGE_CONSTANTS;
        return (
            <div className="success-message">
                <div className="icon-info">
                    <i className="td-icon-sm icon-round-tick" />
                </div>
                <div className="success-message-box">
                    <p className="success-message-title">{Parser(validationSuccessMsg)}</p>
                </div>
            </div>
        );
    }

    clearFormFields() {
        const that = this;
        const href = window.location.href;
        const pathname = href.substring(href.lastIndexOf('/'));
        if (pathname === '/your-id-details') {
            this.setState({ back: true });
        }
        window.removeEventListener('popstate', that.clearFormFields);
    }
}
const validate = (values) => {
    const errors = {};
    const {
        identityType = '',
        stateOfIssue = '',
        licenseNumber = '',
        dlCardNumber = '',
        title = '',
        firstName = '',
        middleName = '',
        familyName = '',
        birthDate = '',
        visaNumber = '',
        expiry = '',
        passportNumber = '',
        countryOfIssue = '',
        noMiddleName = false,
        immiCardNumber = '',
        singleName
    } = values;

    const {
        idTypeElement = {},
        idDetails: { drivingLicense = {}, australianVisa = {}, medicareCard = {}, australianPassport = {}, immiCard = {} }
    } = ID_PAGE_CONSTANTS;
    const { dlStateElement, dlNumberElement, dlCardNumberElement, dlFirstNameElement, dlMiddleNameElement, dlLastNameElement, dobElement, salutation } = drivingLicense;
    // Aus Visa
    const { mcCardNumberElement: cardNumber, mcRefNumberElement: individualReferenceNumber, mccardColorElement: cardColour, mcCardExpiryElement: cardExpiry } = medicareCard;
    const { intlppNumberElement, intlppCountryElement } = australianVisa;
    // Aus Passport
    const { appNumberElement } = australianPassport;
    // Immicard
    const { imCardNumberElement } = immiCard;

    if (isEmpty(identityType)) {
        errors.identityType = idTypeElement.error;
    }

    // Driving License
    if (identityType === 'AUS_DRIVERS_LICENSE') {
        if (isEmpty(stateOfIssue)) {
            errors.stateOfIssue = dlStateElement.error.empty || 'Please select a state';
        }

        if (isEmpty(licenseNumber)) {
            errors.licenseNumber = dlNumberElement.error.empty;
        } else if (!validateLicense(licenseNumber, stateOfIssue)) {
            errors.licenseNumber = dlNumberElement.error.invalid;
        }

        if (isEmpty(dlCardNumber)) {
            errors.dlCardNumber = dlCardNumberElement.error.empty;
        } else if (!validateLicenseCardNumber(stateOfIssue, dlCardNumber)) {
            errors.dlCardNumber = dlCardNumberElement.error.invalid;
        }
    }

    // Australin Passport
    if (identityType === 'AUS_PASSPORT') {
        if (isEmpty(passportNumber)) {
            errors.passportNumber = appNumberElement.error.empty;
        } else if (!validatePassport(passportNumber)) {
            errors.passportNumber = appNumberElement.error.invalid;
        }
    }
    // Australin VISA (International passport)
    if (identityType === 'AUS_VISA') {
        if (isEmpty(visaNumber)) {
            errors.visaNumber = intlppNumberElement.error.empty;
        } else if (!validateInternationalPassport(visaNumber)) {
            errors.visaNumber = intlppNumberElement.error.invalid;
        }

        if (isEmpty(countryOfIssue)) {
            errors.countryOfIssue = intlppCountryElement.error.empty;
        }
    }
    // Immicard
    if (identityType === 'IMMI_CARD') {
        if (isEmpty(immiCardNumber)) {
            errors.immiCardNumber = imCardNumberElement.error.empty;
        } else if (!validateImmiCard(immiCardNumber)) {
            errors.immiCardNumber = imCardNumberElement.error.invalid;
        }
    }
    // Medicare
    if (identityType === 'MEDICARE_CARD') {
        if (isEmpty(values.cardNumber)) {
            errors.cardNumber = cardNumber.error.empty;
        } else if (!validateMedicare(values.cardNumber)) {
            errors.cardNumber = cardNumber.error.invalid;
        }
        if (isEmpty(values.individualReferenceNumber) || values.individualReferenceNumber === 'default') {
            errors.individualReferenceNumber = individualReferenceNumber.error.empty;
        }
        if (isEmpty(values.cardColour) || values.cardColour === 'default') {
            errors.cardColour = cardColour.error.empty;
        }

        if (isEmpty(expiry) && values.cardColour === 'G') {
            errors.expiry = 'Required field. Please use format MM/YYYY.';
        } else if (isEmpty(expiry) && values.cardColour !== 'G') {
            errors.expiry = 'Required field. Please use format DD/MM/YYYY.';
        } else if (!isEmpty(values.cardColour) && values.cardColour !== 'G') {
            const expiryDate = getdayMonthYearFromDate(expiry);
            const expiryYear = expiryDate[2] ? expiryDate[2].trim() : '';
            const expiryMonth = expiryDate[1] ? expiryDate[1].trim() : '';
            if (expiryMonth.length !== 2) {
                errors.expiry = cardExpiry.error.invalid;
            } else if (!isEmpty(expiryYear) && !validateExpireDate(expiry)) {
                errors.expiry = cardExpiry.error.pastExpiry;
            }
        } else if (!isEmpty(values.cardColour) && values.cardColour === 'G') {
            const expiryDate = getdayMonthYearFromDate(expiry);
            const expiryMonth = expiryDate[0] ? expiryDate[0].trim() : '';
            if (expiryMonth.length !== 2) {
                errors.expiry = cardExpiry.error.invalid1;
            } else if (!validateExpireDate(expiry, true)) {
                errors.expiry = cardExpiry.error.pastExpiry;
            }
        }
    }
    if (isEmpty(title)) {
        errors.title = salutation.error;
    }

    //validate single name and store error if invalid
    errors.singleName = validateSingleName(singleName);

    //validate first name and store error if invalid
    errors.firstName = validateFirstName(firstName);

    //validate last name and store error if invalid
    errors.familyName = validateLastName(familyName);

    if (!noMiddleName) {
        if (isEmpty(middleName)) {
            errors.noMiddleName = 'Please tick the box if you don’t have a middle name.';
        } else {
            //validate middle name and store error if invalid
            errors.middleName = validateMiddleName(middleName);
        }
    }
    //validate birth date and store error if invalid
    errors.birthDate = validateBirthDate(birthDate);

    return errors;
};
const fieldList = [
    'identityType',
    'stateOfIssue',
    'licenseNumber',
    'dlCardNumber',
    'cardNumber',
    'cardColour',
    'expiry',
    'individualReferenceNumber',
    'visaNumber',
    'countryOfIssue',
    'passportNumber',
    'title',
    'firstName',
    'middleName',
    'noMiddleName',
    'familyName',
    'birthDate',
    'immiCardNumber',
    'mononymousUser'
];
const identityDetailsForm = reduxForm({
    form: 'identityDetailsForm',
    validate,
    destroyOnUnmount: false,
    enableReinitialize: false,
    onSubmit: () => false,
    onSubmitFail: submitFailure(fieldList)
})(YourIdDetails);

const ConnectedFormAndState = connect((state) => {
    const {
        uiState: { selectedCustomerType: customerType, loggedIn },
        customer: { dvsCheckRequired } = {}
    } = state.app.appData;
    const { loading: { isModuleLoaded = false } = {} } = state.yourIdDetails || {};
    const {
        app: {
            loggedInCustomerData: { title: defaultTitle, familyNames, givenNames, middleNames, dob: defaultDOB, anonymous } = {},
            customerDataFetchSuccess
        } = {}
    } = state;
    const dvsCheckedMode = loggedIn && dvsCheckRequired && customerDataFetchSuccess && !anonymous;
    const {
        title,
        dlNumber = '',
        identityType = '',
        licenseNumber,
        dlCardNumber,
        firstName,
        middleName,
        familyName,
        birthDate,
        cardNumber = '',
        cardColour = '',
        expiry,
        passportNumber,
        visaNumber,
        countryOfIssue,
        stateOfIssue = '',
        noMiddleName = false,
        individualReferenceNumber,
        immiCardNumber,
        singleName,
        mononymousUser = false
    } = getFormValues('identityDetailsForm')(state) || {};
    const dateOfBirth = getdayMonthYearFromDate(birthDate);
    const dobDay = dateOfBirth[0] ? dateOfBirth[0].trim() : '';
    const dobMonth = dateOfBirth[1] ? dateOfBirth[1].trim() : '';
    const dobYear = dateOfBirth[2] ? dateOfBirth[2].trim() : '';

    // if driver's licence is prefilled then preselect form. but not if there is existing value
    const selectedIdentityType = isEmpty(identityType) ? 'AUS_DRIVERS_LICENSE' : identityType;
    const initialValues = { identityType: selectedIdentityType, customerType };
    if (dvsCheckedMode) {
        initialValues.identityType = identityType;
        initialValues.title = defaultTitle;
        initialValues.firstName = givenNames;
        initialValues.middleName = middleNames;
        initialValues.noMiddleName = !middleNames;
        initialValues.familyName = familyNames;
        const defaultDateOfBirth = getdayMonthYearFromDate(defaultDOB, '-');
        initialValues.dobDay = defaultDOB && defaultDateOfBirth[2] ? defaultDateOfBirth[2].trim() : '';
        initialValues.dobMonth = defaultDOB && defaultDateOfBirth[1] ? defaultDateOfBirth[1].trim() : '';
        initialValues.dobYear = defaultDOB && defaultDateOfBirth[0] ? defaultDateOfBirth[0].trim() : '';
        initialValues.birthDate = defaultDOB && initialValues.dobDay + ' / ' + initialValues.dobMonth + ' / ' + initialValues.dobYear;
    }

    // Get child form state to display generic error.
    let isSelectedFormSubmitted = false;
    let isSelectedFormInvalid = false;

    let formFields = {};
    const noMiddleNameData = !noMiddleName ? { middleName, noMiddleName } : { noMiddleName };
    const basicDetails = {
        title,
        firstName,
        familyName,
        dobDay,
        dobMonth,
        dobYear,
        ...noMiddleNameData,
        mononymousUser,
        singleName
    };

    if (identityType === 'AUS_DRIVERS_LICENSE') {
        isSelectedFormSubmitted = hasSubmitFailed('drivingLicenseForm')(state);
        isSelectedFormInvalid = isInvalid('drivingLicenseForm')(state);
        formFields = Object.assign(
            {},
            {
                idType: identityType,
                stateOfIssue,
                licenseNumber,
                dlCardNumber,
                ...basicDetails
            }
        );
        if (dvsCheckedMode) {
            initialValues.stateOfIssue = stateOfIssue;
            initialValues.licenseNumber = licenseNumber;
            initialValues.dlCardNumber = dlCardNumber;
        }
    } else if (identityType === 'AUS_PASSPORT') {
        isSelectedFormSubmitted = hasSubmitFailed('australianPassportForm')(state);
        isSelectedFormInvalid = isInvalid('australianPassportForm')(state);
        formFields = Object.assign(
            {},
            {
                idType: identityType,
                givenName: firstName,
                passportNumber,
                ...basicDetails
            }
        );
        if (dvsCheckedMode) {
            initialValues.passportNumber = passportNumber;
        }
    } else if (identityType === 'MEDICARE_CARD') {
        let expiryDateDay;
        let expiryDateMonth;
        let expiryDateYear;
        const expiryDate = getdayMonthYearFromDate(expiry);
        if (cardColour === 'G') {
            expiryDateMonth = expiryDate[0] ? expiryDate[0].trim() : '';
            expiryDateYear = expiryDate[1] ? expiryDate[1].trim() : '';
        } else {
            expiryDateDay = expiryDate[0] ? expiryDate[0].trim() : '';
            expiryDateMonth = expiryDate[1] ? expiryDate[1].trim() : '';
            expiryDateYear = expiryDate[2] ? expiryDate[2].trim() : '';
        }
        isSelectedFormSubmitted = hasSubmitFailed('medicareForm')(state);
        isSelectedFormInvalid = isInvalid('medicareForm')(state);
        formFields = Object.assign(
            {},
            {
                idType: identityType,
                cardColour,
                cardNumber,
                expiryDateDay,
                expiryDateMonth,
                expiryDateYear,
                individualReferenceNumber,
                ...basicDetails
            }
        );
        if (dvsCheckedMode) {
            initialValues.cardNumber = cardNumber;
            initialValues.cardColour = cardColour;
            initialValues.expiry = expiry;
            initialValues.individualReferenceNumber = individualReferenceNumber;
        }
    } else if (identityType === 'AUS_VISA') {
        isSelectedFormSubmitted = hasSubmitFailed('australianVisaForm')(state);
        isSelectedFormInvalid = isInvalid('australianVisaForm')(state);
        formFields = Object.assign(
            {},
            {
                idType: identityType,
                givenName: firstName,
                passportNumber: visaNumber,
                countryOfIssue,
                ...basicDetails
            }
        );
        if (dvsCheckedMode) {
            initialValues.visaNumber = visaNumber;
            initialValues.countryOfIssue = countryOfIssue;
        }
    } else if (identityType === 'IMMI_CARD') {
        isSelectedFormSubmitted = hasSubmitFailed('immiCardForm')(state);
        isSelectedFormInvalid = isInvalid('immiCardForm')(state);
        formFields = Object.assign(
            {},
            {
                idType: identityType,
                givenName: firstName,
                immiCardNumber,
                ...basicDetails
            }
        );
        if (dvsCheckedMode) {
            initialValues.immiCardNumber = immiCardNumber;
        }
    }
    const defaultState = { back: false, formFields, identityType, cardColour, customerType, stateOfIssue, isSelectedFormSubmitted, isSelectedFormInvalid, noMiddleName, mononymousUser };

    return (isModuleLoaded && !isEmpty(dlNumber)) || dvsCheckedMode ? { ...defaultState, initialValues } : { ...defaultState };
})(identityDetailsForm);

export default ConnectedFormAndState;
